
.loginLogo img{
    height: 200px;

        
}
.logoTopLogin {
    text-align: center;
    padding-bottom: 10px;
    /* display: none; */
    /* opacity: 80%; */
}
.logoTopLogin img{
    /* height: 100px; */
    width: 300px;
}
@media (max-width: 900px){
    .hermonLoginPicture{
        display: none !important;
    }

    .logoTopLogin{
        display: block;
    }
}