/*Now the CSS*/
body {
    line-height: 1.5 !important;
}
.arrowUp:hover{
    cursor: pointer;
}
  .tree{
    height: 700px;
  }
  .tree ul {
    padding-top: 20px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-left: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .tree li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
  
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  .treeUserProfile{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  .treeImg{
    height: 90px;
    width: 90px;
    border-radius: 40px;
    border-color: #000;
   
  }
  .addLink{
    text-decoration: none;
    color: #151515;
  }

  .linkSpan{
    background-color: #E7E7E7;
  }
  .linkSpan:hover{
    background-color: #D9D9D9;

  }
  /*We will use ::before and ::after to draw the connectors*/
  
  .tree li::before,
  .tree li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 50%;
    height: 20px;
  }
  .tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
  }
  
  /*We need to remove left-right connectors from elements without 
  any siblings*/
  .tree li:only-child::after,
  .tree li:only-child::before {
    display: none;
  }
  
  /*Remove space from the top of single children*/
  .tree li:only-child {
    padding-top: 0;
  }
  
  /*Remove left connector from first child and 
  right connector from last child*/
  .tree li:first-child::before,
  .tree li:last-child::after {
    border: 0 none;
  }
  /*Adding back the vertical connector to the last nodes*/
  .tree li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
  }
  .tree li:first-child::after {
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
  }
  
  /*Time to add downward connectors from parents*/
  .tree ul ul::before {
     content: "";
     position: absolute;
     top: 0; 
    left: 50%; 
    border-left: 1px solid #ccc; 
    width: 0;
    height: 20px; /*height of downward connectors*/ 
  }
  
  .tree li span {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
  
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
  
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  /*Time for some hover effects*/
  /*We will apply the hover effect the the lineage of the element also*/
  .tree li span:hover,
  .tree li span:hover + ul li span {
    /* background: #c8e4f8; */
    background: #D9D9D9;
    color: #000;
    border: 1px solid #94a0b4;
    cursor: pointer;
  }
  /*Connector styles on hover*/
  .tree li span:hover + ul li::after,
  .tree li span:hover + ul li::before,
  .tree li span:hover + ul::before,
  .tree li span:hover + ul ul::before {
    border-color: #94a0b4;
  }
  