/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700;900&family=Source+Sans+Pro:wght@400;600;700&display=swap'); */

  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


html,
body,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: 'Inter',sans-serif;
}


.material-symbols-sharp {
  font-variation-settings:
  'FILL' 1,
  'wght' 100,
  'GRAD' 200,
  'opsz' 48,
  
}

.material-symbols-sharp {
  font-size: 100px;
}


.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 10px;
}

/*Track*/
::-webkit-scrollbar-track{
  background: #e0e0e0;
}
/*Handle*/
::-webkit-scrollbar-thumb{
background:  #888;
}

/*Hover*/
::-webkit-scrollbar-track:hover{
  background: #555;
}

/* Dark and light */

.invert-dark{
  color: white;
}