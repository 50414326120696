.multiStepButton{
    background-color: #151515 !important;
}
/* .multiStepButton:hover{
    background-color: #D9D9D9 
} */
.promoCodeButton{
    background-color: rgb(228, 228, 19);
}
.checkIfExist{
    background-color: #D9D9D9 !important;
}
.checkIfExist:hover{
    background-color: #C2C2C2 !important;
}